
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import Notes from '@/components/organisms/dms/OnlineAgreement/Notes.vue'
import AppButton from '@/components/atoms/AppButton.vue'

export default defineComponent({
  name: 'OnlineAgreement',
  components: {
    Notes,
    AppButton,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const setAutoCollectData = async (autoCollectData: any) => {
      await store.dispatch('setAutoCollectData', autoCollectData)
    }

    return {
      router,

      async toInput () {
        setAutoCollectData({})
        await router.push({ name: 'OnlineInputPage', params: { agreement: 'true' } })
      },
    }
  },
})
