
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import OnlineAgreement from '@/components/organisms/dms/OnlineAgreement/OnlineAgreement.vue'
import Breadcrumb from '@/components/organisms/dms/common/Breadcrumb.vue'
export default defineComponent({
  components: {
    OnlineAgreement,
    Breadcrumb,
  },
  setup () {
    const router = useRouter()
    const BreadcrumbList = [
      { text: '利用規約への同意', numFlag: true },
      { text: '資料情報の入力', numFlag: true },
      { text: '内容確認', numFlag: true },
      { text: '完了', numFlag: false },
    ]
    return {
      router,
      BreadcrumbList,
    }
  },
})
