
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Usage',
  setup () {
    return {
    }
  },
})
